:root {
  --primary: #f2ddad;
  --secondary: #48416a;
  --tertiary: #598678;
  --black: #362c45;
}

body {
  margin: 0px;
  background: var(--primary);
}
